import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter, Route, PublicPage } from "react-router-dom";
import { ChatBox } from './Common/ChatBox';

import { Helmet } from "react-helmet";
import "./Assets/SCSS/main.scss";
import "./Assets/SCSS/header.scss";
import "./Assets/SCSS/footer.scss";

import ScrollToTop from "./ScrollToTop";
import Header from "./Common/header";
import Footer from "./Common/footer";
import {
  loadContentsForPages,
  withLoadableContents,
} from "./routes/withDynamicContent";
import RealEstate from "./Containers/RealEstate";
import GeoLocation from "./Containers/GeoLocation/";
import Referrals from "./Containers/Referrals/";
import Blog from "./Containers/Blog/";

import { createTheme as createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import appRoutes from "./routes/painting/";

/*
  Contents for PROD websites
  For DEV we fetch data directly in runtime
  Last modified date: 6_7_2022.
*/
import flooringContent from "./Assets/contents/flooring_content.json";
import paintingContent from "./Assets/contents/painting_content.json";
import sunroomContent from "./Assets/contents/sunroom_content.json";
import Promotion from "./routes/Promotion";

const GetStarted = React.lazy(() => import("./Containers/GetStarted/index"));
const ThankYou = React.lazy(() => import("./Containers/ThankYou/index"));
const Finishes = React.lazy(() => import("./Containers/Finishes/index"));
const PrivacyPolicy = React.lazy(() => import("./Containers/PrivacyPolicy"));
const setup = React.lazy(() => import("./Views/setup"));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#46bfee",
      main: "#46bfee",
      dark: "#46bfee",
      contrastText: "#fff",
    },
    secondary: {
      main: "#46bfee",
    },
  },
});

const contentsDictionary = {
  flooringContent,
  paintingContent,
  sunroomContent
};

const IS_DEV = !!(
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_IS_STAGING === "true"
);

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const paramsWebsites = {
  painting: () => ({
    REACT_APP_NAME: "painting",
    REACT_APP_ISNOT_DESIGN3D: true,
    REACT_APP_PROJECT_TYPE: "kitchen",
    REACT_APP_SHOW_3D_TOOL_CONTENT: false,
    REACT_APP_TITLE: "AGM Painting Renovations",
    REACT_APP_URL: "https://paintingrenovations.com",
    REACT_APP_META_DESCRIPTION:
      "We transform your dreams in reality. AGM Renovations experts provide quality bathroom finishing services throughout the GTA and the surrounding area. We offer 100% satisfaction guarantee on our workmanship and offer competitive prices with exceptional quality.",
    REACT_APP_META_KEYWORDS:
      "bathroom renovations toronto, AGM basements, bathroom renovations toronto costs, finishing a bathroom cost estimator, finished basements, finished bathroom costs, bathroom finishing financing, AMG design, high qualty basements toronto, bathroom renovations vaughan, bathroom renovations in GTA, bathroom finish, steps to finishing a bathroom,",
    REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID: "GTM-P6B264Q",
    REACT_APP_PAINTING_DOCUMENT_ID: "1o_ewEVl3huJhtEB5QucpoYNwQPxwkLXAlg0Da6Lu5-4",
    REACT_APP_PROD_CONTENTS_JSON_FILENAME: "paintingContent",
  }),
  flooring: () => ({
    REACT_APP_NAME: "flooring",
    REACT_APP_PROJECT_TYPE: "kitchen",
    REACT_APP_ISNOT_DESIGN3D: true,
    REACT_APP_SHOW_3D_TOOL_CONTENT: false,
    REACT_APP_TITLE: "AGM Flooring Renovations",
    REACT_APP_URL: "https://flooringrenovations.com",
    REACT_APP_META_DESCRIPTION:
      "We transform your dreams in reality. AGM Renovations experts provide quality bathroom finishing services throughout the GTA and the surrounding area. We offer 100% satisfaction guarantee on our workmanship and offer competitive prices with exceptional quality.",
    REACT_APP_META_KEYWORDS:
      "bathroom renovations toronto, AGM basements, bathroom renovations toronto costs, finishing a bathroom cost estimator, finished basements, finished bathroom costs, bathroom finishing financing, AMG design, high qualty basements toronto, bathroom renovations vaughan, bathroom renovations in GTA, bathroom finish, steps to finishing a bathroom,",
    REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID: "GTM-KMVKLPN",
    REACT_APP_PAINTING_DOCUMENT_ID: "1E_O78mX8WGJnoyLxCTuXNnlre0WvHgTmK0jP9UWzcf4",
    REACT_APP_PROD_CONTENTS_JSON_FILENAME: "flooringContent",
  }),
  sunroom: () => ({
    REACT_APP_NAME: "sunroom",
    REACT_APP_TITLE: "AGM Sunroom Renovations",
    REACT_APP_ISNOT_DESIGN3D: true,
    REACT_APP_PROJECT_TYPE: "kitchen",
    REACT_APP_SHOW_3D_TOOL_CONTENT: false,
    REACT_APP_URL: "https://agmhomeadditions.com/",
    REACT_APP_META_DESCRIPTION:
      "We transform your dreams in reality. AGM Renovations experts provide quality kitchen finishing services throughout the GTA and the surrounding area. We offer 100% satisfaction guarantee on our workmanship and offer competitive prices with exceptional quality.",
    REACT_APP_META_KEYWORDS:
      "kitchen renovations toronto, AGM Sunroom renovations toronto costs, finishing a Sunroom cost estimator, finished Sunrooms, finished Sunroom costs, kitchen finishing financing, AMG design, high qualty basements toronto.",
    REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID: "GTM-MG9DKG4",
    REACT_APP_PAINTING_DOCUMENT_ID: "1FqSPyPKy9RB7gDC3jPqHwel4u9kbrevs21MFJUBt_IU",
    REACT_APP_PROD_CONTENTS_JSON_FILENAME: "sunroomContent",
  }),
};

let defaultSiteParams = null;
if (IS_DEV) {
  let config;
  if (params.sitename && paramsWebsites[params.sitename]) {
    config = paramsWebsites[params.sitename];
  } else if (
    process.env.REACT_APP_NAME &&
    paramsWebsites[process.env.REACT_APP_NAME]
  ) {
    config = paramsWebsites[process.env.REACT_APP_NAME];
  }

  if (typeof config === "function") {
    defaultSiteParams = config();
  }
} else {
  defaultSiteParams = Object.keys(process.env).reduce((acc, key) => {
    if (process.env[key] === "true") {
      acc[key] = true;
    } else if (process.env[key] === "false") {
      acc[key] = false;
    } else {
      acc[key] = process.env[key];
    }

    return acc;
  }, {});
}

function App(props) {
  const [jsonData, setJsonData] = useState(window.$json);
  // const [location, setLocation] = useState(null);
  const siteParams = defaultSiteParams;
  const [devContents, setDevContents] = useState(null);
  // const devContents = null;

  function updateStateJson(json) {
    setJsonData(json);
  }

  useEffect(() => {
    if (IS_DEV && siteParams.REACT_APP_PAINTING_DOCUMENT_ID) {
      loadContentsForPages(siteParams.REACT_APP_PAINTING_DOCUMENT_ID).then(
        (contentCache) => {
          setDevContents(contentCache);
          window.downloadMappedObjectAsJSON = function downloadObjectAsJson(
            exportName = `mappedObject_${new Date().toLocaleDateString()}`
          ) {
            var dataStr =
              "data:text/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(contentCache));
            var downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", exportName + ".json");
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
          };
        }
      );
    }

    loadScripts();
  }, []);

  // TODO store env.files in paramsWebsites, in order to load a particular site by Queryparams
  // prevent App.js from rerender, as it renders all the content
  // Use siteParams data by passing through props, instead decllaring process.env in components

  // For passing as prop instead of declaring on each component
  // Still need to clean up old files. Just updating as I go for now
  const isKitchenApp = true;

  let content = devContents;

  if (!IS_DEV) {
    const contentsByDictionary = siteParams
      ? contentsDictionary[siteParams.REACT_APP_PROD_CONTENTS_JSON_FILENAME]
      : contentsDictionary[process.env.REACT_APP_PROD_CONTENTS_JSON_FILENAME];
    content = contentsByDictionary;
  }

  const footerSectionProps = content?.["HOME"]?.footerSectionProps ?? {};

  let headerSlideTitle = "Get your free estimate today!!!!";
  if (["flooring", "painting"].includes(siteParams.REACT_APP_NAME)) {
    headerSlideTitle = "Call today and save $1,000";
  } else if (["sunroom"].includes(siteParams.REACT_APP_NAME)) {
    headerSlideTitle = "Сall today and save $3,000";
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
         <Helmet>
           <title>{siteParams.REACT_APP_TITLE}</title>
           <meta name="description" content={siteParams.REACT_APP_META_DESCRIPTION} />
           <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
           <meta name="robots" content="noindex"/>
         </Helmet>
        <ScrollToTop>
          <Header
            isKitchenApp={isKitchenApp}
            isShowlogos={jsonData && jsonData.countryCode !== "US"}
          />
          <Suspense fallback={<></>}>
            {siteParams &&
              appRoutes.map((route, ind, arr) => {
              
                let {
                  name,
                  RenderedComponent,
                  renderWithData,
                  sheetName,
                  ...routeProps
                } = route;
                const renderProp = {
                  render: () => {
                    if (!RenderedComponent && !!renderWithData) {
                      RenderedComponent = renderWithData;
                    }

                    if (IS_DEV) {
                      if (sheetName) {
                        RenderedComponent = withLoadableContents(
                          RenderedComponent,
                          {
                            sheetName,
                            documentId:
                              siteParams.REACT_APP_PAINTING_DOCUMENT_ID,
                          }
                        );
                      }

                      return (
                        <RenderedComponent
                          {...siteParams}
                          show3dDesignToolContent={
                            siteParams.REACT_APP_SHOW_3D_TOOL_CONTENT
                          }
                          isKitchenApp={isKitchenApp}
                          location={jsonData}
                          data={jsonData}
                        />
                      );
                    } else {
                      const prodContentsForPage = content && content[sheetName];

                      return (
                        <RenderedComponent
                          {...siteParams}
                          show3dDesignToolContent={
                            siteParams.REACT_APP_SHOW_3D_TOOL_CONTENT
                          }
                          isKitchenApp={isKitchenApp}
                          content={
                            prodContentsForPage ? prodContentsForPage : {}
                          }
                          location={jsonData}
                          data={jsonData}
                        />
                      );
                    }
                  },
                };

                return <Route key={name} {...routeProps} {...renderProp} />;
              })}
          </Suspense>
          <Suspense fallback={<></>}>
            <Route exact path="/get-started" component={GetStarted} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/finishes" component={Finishes} />
            <Route exact path="/setup" component={setup} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/real-estate" component={RealEstate} />
            <Route exact path="/promotion-agm" component={Promotion} />
            <Route exact path="/referrals" component={Referrals} />
            <Route exact path="/blog" component={Blog} />

          </Suspense>
          <GeoLocation
            data={jsonData}
            setGeoData={setJsonData}
            updateUserLocation={() => {}}
            visible={true}
          />
          {process.env.REACT_APP_NAME !== 'sunroom' ? <ChatBox/> : null}
          <Footer
            headerSlideTitle={headerSlideTitle}
            isKitchenApp={process.env.REACT_APP_NAME}
            data={jsonData}
            content={footerSectionProps}
          />
        </ScrollToTop>
      </ThemeProvider>
    </BrowserRouter>
  );

  function PublicPage() {
    return (window.location = process.env.PUBLIC_URL);
  }
}

function loadScripts() {
  // var tid = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

  // var s = document.createElement('script');
  // s.type = 'text/javascript';
  // s.innerHTML = `
  //   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','${tid}');
  // `;
  // document.head.appendChild(s);

  // var f = document.createElement('iframe');
  // f.src=`https://www.googletagmanager.com/ns.html?id=${tid}`
  // f.style="display:none;visibility:hidden"
  // document.body.appendChild(s);

  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.href =
    "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap";
  document.head.appendChild(link);

  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.href =
    "https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@700&display=swap";
  document.head.appendChild(link);
}

export default App;
